import axios from 'axios';

/**
 *
 * @param {*} userId
 * @param {*} spaceId
 * @param {*} sessionId
 * @param {*} recentSpaceId
 * @returns Promise
 * @author CHOI DAE GEON
 */
const checkDupleUse = async (userId, spaceId, sessionId, recentSpaceId) => {
  return await axios.post('/api/spaceSession/checkDupleUse', { userId, spaceId, sessionId, recentSpaceId });
};

export { checkDupleUse };
