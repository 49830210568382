import axios from "axios";

/**
 *
 * @param {number} latitude
 * @param {number} longitude
 * @param {string} spaceId
 * @returns
 */
const getCurrentWeather = async (latitude = -1, longitude = -1, spaceId = '') => {
  return await axios.post('/api/weather/currentWeather', {
    longitude,
    latitude,
    spaceId
  });
};

export { getCurrentWeather };
